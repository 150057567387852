<template>
  <!-- 客服工作台 -->
  <div class="workbench-box">
    <div class="workbench-container">
      <workbench-chat :key="num"></workbench-chat>
    </div>
  </div>
</template>

<script>
/**
 * @description 客服工作台
 * 接入环信，实现客服聊天功能
 */
import workbenchChat from "@/views/customerWorkbench/components/workbench-chat";

export default {
  data() {
    return {
      num: 1,
    };
  },
  components: {
    workbenchChat,
  },
  watch: {
    $route: {
      handler(to) {
        // 环信聊天
        if (to.meta.workbench && to.query.t) {
          // 解决聊天 点击刷新选项卡不刷新的问题
          this.num = to.query.t;
        }
      },
    },
  },
  mounted() {},
  created() {},
  destroyed() {},
  methods: {},
};
</script>

<style lang="scss" scoped>
.workbench-box {
  // padding: 10px;
  height: 100%;
  .workbench-container {
    width: 100%;
    height: 100%;
    background-color: #fff;
    // border: 1px solid #dedcda;
    display: flex;
    flex-direction: column;
    border-radius: 3px;
    // padding: 10px;
  }
}
</style>
