import axios from "@/config/httpConfig";
// 判断该组织是否可以试用
export function getTrialQualification(data) {
  return axios.post("huanxinTrial/getTrialQualification", data);
}
// 试用账号
export function TrialAccount(data) {
  return axios.post("huanxinTrial/TrialAccount", data);
}
// 保存坐席绑定
export function bindAgent(data) {
  return axios.post("huanxinAgent/bindAgent", data);
}
// 获取jwt密钥
export function getJWT(data) {
  return axios.post('/customerParameter/getHuanxinJWT', data)
}

// 获取绑定坐席列表
export function getBindAgents(data) {
  return axios.post("huanxinAgent/getBindAgents", data);
}
// 删除绑定的坐席
export function delBindAgent(data) {
  return axios.post("huanxinAgent/delBindAgent", data);
}
// 判断获取环信token是否成功
export function getAccessTokenResult(data) {
  return axios.post("huanxinAgent/getAccessTokenResult", data);
}
// 查询坐席
export function getAgents(data) {
  return axios.post("huanxinAgent/getAgents", data);
}
// 新建坐席
export function newAgent(data) {
  return axios.post("huanxinAgent/newAgent", data);
}
// 启用/禁用坐席
export function enableAgent(data) {
  return axios.post("huanxinAgent/enableAgent", data);
}
// 删除坐席
export function deleteAgent(data) {
  return axios.post("huanxinAgent/deleteAgent", data);
}
// 更新坐席（暂时应该没用）
export function updateAgent(data) {
  return axios.post("huanxinAgent/updateAgent", data);
}
// 保存环信配置
export function saveHuanXinConfig(data) {
  return axios.post("huanxinTrial/saveHuanXinConfig", data);
}
// 获取单点登录jwt
export function getHuanxinJWT(data) {
  return axios.post("customerParameter/getHuanxinJWT", data);
}
// 更改环信开启状态
export function changeHuanxinState(data) {
  return axios.post("huanxinTrial/changeHuanxinState", data);
}
// 获取视图列表页面信息
export function getViewListRecently(data) {
  return axios.post('/view/list/getViewListRecently', data)
}