<template>
  <div class="chat-container">
    <!-- <div class="close-button">
      <svg class="icon" aria-hidden="true" @click="closeChat">
        <use href="#icon-chahao"></use>
      </svg>
    </div> -->
    <div class="not-open" v-if="!chatDialogShow">
      <img :src="defaultImg" alt="" />
      <p>{{ promptLanguage }}，{{ $t("c966") }}</p>
    </div>
    <div v-if="chatDialogShow" class="chat-dialog"></div>
  </div>
</template>

<script>
/**
 * @description: 工作台聊天组件
 *  功能：
 * 1. 检验环信是否开启
 * 2. iframe嵌入环信工作台
 */
import { getJWT } from "../api.js";
import * as chatsettingsApi from "../api.js";
export default {
  data() {
    return {
      // 缺省图
      defaultImg: require("@/views/systemSettings/components/chatSetting/Img/study.png"),
      // 环信密钥
      jwt: "",
      // 是否展示环信
      chatDialogShow: true,
      // 提示语 环信服务未开启
      promptLanguage: this.$i18n.t("c965"),
    };
  },
  mounted() {
    //展示环信客服工作台
    this.getStatus();
    // this.$Bus.$on('show-workbench-chat', res => {
    //   this.chatDialogShow = true;
    // })
  },
  methods: {
    /**
     * @description: 获取环信状态
     */
    async getStatus() {
      // 判断该组织状态
      let res = await chatsettingsApi.getTrialQualification();
      if (res.result) {
        if (res.data && res.data.config) {
          if (res.data.config.status === 0 || res.data.config.status === 1) {
            this.chatDialogShow = true;
            if (this.jwt == "") {
              this.getJWT();
            }
          } else {
            this.chatDialogShow = false;
          }
        } else {
          this.chatDialogShow = false;
        }
      }
    },
    /**
     * @description: 获取jwt密钥
     */
    async getJWT() {
      let result = await getJWT();
      if (result.result && result.returnCode == "1") {
        this.jwt = result.data.jwt;
        let domain = result.data.domain;
        //iframe嵌入环信工作台
        document.querySelector(".chat-dialog").innerHTML =
          document.querySelector(".chat-dialog").innerHTML +
          `<iframe
      src="https://${domain}/v1/access/jwt?jwt=${this.jwt}&returnTo=https://${domain}/mo/agent/webapp/chat"
      frameborder="0"
      style="width:100%;height:100%"
      allow="camera *; microphone *"
    ></iframe>`;
      } else if (result.result && result.returnCode != "1") {
        this.chatDialogShow = false;
        this.promptLanguage = result.returnInfo;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.chat-container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  // position: fixed;
  // bottom: 0;
  // right: 0;
  background: white;
  overflow: hidden;
  .chat-dialog {
    width: 100%;
    height: 100%;
    // height: calc(100% - 30px);
  }
  .close-button {
    width: 100%;
    height: 30px;
    text-align: right;
    padding-right: 10px;
    line-height: 30px;
    .icon {
      cursor: pointer;
      font-size: 12px;
    }
  }
}
.not-open {
  p {
    text-align: center;
  }
}
</style>